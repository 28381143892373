////////////////////////////////////////////////////
//---------------- C O L O R ----------------------
///////////////////////////////////////////////////

// stylelint-disable-next-line scss/dollar-variable-default
$primary   : #000000;
$secondary : #FCC810;

$body-color: #FFF !default;
$body-bg: #000;

$spacer: 1rem !default;
$spacers: (
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  0: 0,
  40: 40px,
  60: 60px,
  80: 80px,
  100: 100px,
  120: 120px,
  140: 140px,
);

$modal-backdrop-opacity: 0.9;
$table-color: #fff;
