@import "common";

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SF-Pro-Display-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SF-Pro-Display-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}

body {
  //font-family: 'SF Pro Display';
  font-family: "Spline Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

p {
  font-size: 16px;
  line-height: 1.70;
}

td {
    border-width: 1px;
    padding-left: 5px;
    padding-right: 5px;
}

.table-borderless td {
    border-width: 0px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-48 {
  font-size: 48px !important;
}


.accordion {
  --bs-accordion-active-color: white;
  --bs-accordion-active-bg: transparent;
}

div#cms-top ~ .navbar-web-cms, div#cms-top ~ #MobileNav {
  top: 46px;
}

.fullbg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay_img {
  background-blend-mode: multiply;
  background-color: rgb(0 0 0 / 54%);
}

.season-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-color: rgb(0 0 0 / 84%);
}

.logo {
  max-width: 150px
}

.boxed-btn {
  background-color: $secondary;
  color: $primary;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-weight: 500;
  text-decoration: none;
}

.navbar-collapse .navbar-nav .nav-item {
  display: inline-block;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 70px;
  margin-left: 40px;
}

.navbar-collapse .navbar-nav .nav-item a, #MobileNav li a {
  transition: none;
  color: #fff;
  text-decoration: none;
}

#MobileNav .dropdown-menu {
  border: none;
}

#MobileNav .dropdown {
  list-style: none;
}
#MobileNav hr{
  width: 90% !important;
}

.dropdown-item {
  line-height: 30px;
}

.close-svg {
  position: absolute;
  top: 15px;
  cursor: pointer;
  right: 15px;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: transparent;
  font-weight: bold;
}

footer hr {
  margin-top: 0;
}

footer a {
  font-size: 12px;
  text-decoration: none;
}

.owl-nav button.owl-prev, .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.owl-nav button.owl-prev span, .owl-nav button.owl-next  span {
  font-size: 60px;
  padding: 10px;
}

.owl-nav button.owl-prev {
  left: 0;
}

.owl-nav button.owl-next {
  right: 0;
}

.owl-nav button.owl-prev:hover, .owl-nav button.owl-next:hover {
  background: rgba(0, 0, 0, 0.8);
}


.frame_area {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  width: 264px;
  height: 534px;
  padding: 12px 14px;
  margin-bottom: 20px;
}


.frame_area::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  z-index: 200;
  background: url('../images/frontend/mobile_frame.png') no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100%;
}

.frame_area .frame_content {
  position: relative;
  overflow: hidden;
  z-index: 100;
  width: 236px;
  height: 100%;
}

.frame_area .frame_content .inner {
  position: relative;
  width: 236px;
  height: 1381px;
  background-repeat: no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-animation: slide01 19.5s ease-in-out forwards infinite;
  animation: slide01 19.5s ease-in-out forwards infinite;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.frame_area .frame_content .bar {
  position: absolute;
  bottom: 0;
  width: 236px;
  height: 53px;
  background: url('../images/frontend/mobile_frame_bar.png') no-repeat;
  -webkit-background-size: 236px 53px;
  background-size: 236px 53px;
}

@keyframes slide01 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  15.3846%, 20.5128% {
    -webkit-transform: translateY(-900px);
    transform: translateY(-900px);
  }
  33.3333%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide01-mobile {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  15.3846%, 20.5128% {
    -webkit-transform: translateY(-670px);
    transform: translateY(-670px);
  }
  33.3333%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.navbar-scrolled {
  background-color: black;
  transition: background-color 0.7s ease, padding 0.7s ease;
}

.navbar-scrolled .navbar-brand img {
  content: url("../images/logo_mini_yellow.png");
  transition: 0.3s;
  max-width: 50px;
  height: 50px;
}

small, .small {
  font-size: 0.875em !important;
}

.footer-icon {
  width: 35px;
}

#accordionFaq .accordion-button:not(.collapsed) {
  box-shadow: none;
}
#accordionFaq .accordion-button:not(.collapsed) h2 {
  color: #fff!important;
}

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #dee2e661;
}

.accordion-item {
  border: none;
}

.blur-text {
  mask: linear-gradient(black, black, transparent);
  backdrop-filter: blur(8px);
}

.download-btn {
  max-width: 200px;
}

#collapsecr:not(.show) {
  display: block;
  height: 200px;
}

.blur-text.show {
  backdrop-filter: blur(0px);
  mask: none;
}

.crbtn:not(.collapsed) {
  display: none;
}

.text-justify {
  text-align: justify;
}

.overlay-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  //background-color: rgb(0 0 0 / 80%);
  background-color: black;
  overflow-x: hidden;
}

.dropdown-language .dropdown-menu {
    border: 1px solid #fff;
    padding: 0;
    font-size: 13px;
}
.dropdown-language .dropdown-menu .dropdown-item {
    border: 1px solid #fff;
}
.dropdown-language .dropdown-menu .active-lang {
  background-color: $secondary;
  a {
    color: #000!important;
  }
}

.reader_web {
  p {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 18px;
  }
}

#cmspage_homepage {
  p {
    font-size: 18px;
    line-height: 1.33;
    color: rgba(255, 255, 255, 0.8);
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 3.5rem;
    font-weight: bold;
    color: #fff;
  }

  h1, h2 {
    font-size: 56px;
  }

  h3 {
    font-size: 48px;
  }

  h4 {
    font-size: 38px;
  }

  h5 {
    font-size: 30px;
  }

  h6 {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  #cmspage_homepage {
    h1, h2 {
      font-size: 38px;
    }

    h3 {
      font-size: 34px;
    }

    h4 {
      font-size: 28px;
    }

    h5 {
      font-size: 22px;
    }

    h6 {
      font-size: 18px;
    }

    h1, .h1, h2, .h2, h3, .h3 {
      line-height: 2.2rem;
    }

    p {
      font-size: 14px;
    }
  }


  .logo {
    max-width: 100px;
  }
  footer .logo {
    max-width: 85px;
  }
  .navbar-scrolled .navbar-brand img {
    max-width: 34px;
    height: 28px;
  }

  .download-btn {
    max-width: 120px;
  }


  .frame_area {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    width: 160px;
    height: 534px;
    padding: 6px 9px;
  }
  .frame_area .frame_content {
    position: relative;
    overflow: hidden;
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .frame_area .frame_content .bar {
    background-size: 144px 37px;
    width: 144px;
    height: 28px;
  }
  .frame_area {
    width: 160px;
    height: 325px;
    padding: 6px 9px;
  }
  .frame_area .frame_content .inner {
    width: 144px;
    -webkit-animation: slide01-mobile 19.5s ease-in-out forwards infinite;
    animation: slide01-mobile 19.5s ease-in-out forwards infinite;
  }
}

@media (max-width: 767px) {
  #cmspage_homepage {
    h1, h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 20px;
    }

    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 16px;
    }

    h1, .h1, h2, .h2, h3, .h3 {
      line-height: 2.2rem;
    }

    p {
      font-size: 14px;
    }
  }
}
